import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from './dashboard/dashboard-layout/dashboard-layout.component';
import { LoginComponent } from './dashboard/login/login.component';
import { ResetPasswordComponent } from './dashboard/reset-password/reset-password.component';
import { FlagErrorsComponent } from './flag-errors/flag-errors.component';
import { AuthGuard } from './services/auth.guard';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ForgotPasswordComponent } from './dashboard/forgot-password/forgot-password.component';
import { RegisterComponent } from './user/register/register.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent
  },
  {
    path: 'signup',
    component: RegisterComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'fs',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'test-flag-errors',
        component: FlagErrorsComponent,
        canActivate: [AuthGuard],
        data: {
          role: ['superadmin', 'admin', 'facilitator']
        }
      },
      {
        path: 'mcq',
        loadChildren: () => import('./mcq/mcq.module').then(m => m.McqModule),
        canActivate: [AuthGuard],
        data: {
          role: ['superadmin', 'admin', 'facilitator']
        }
      },
      {
        path: 'practice',
        loadChildren: () => import('./practice-series/practice-series.module').then(m => m.PracticeSeriesModule),
        canActivate: [AuthGuard],
        data: {
          role: ['superadmin', 'admin', 'facilitator']
        }
      },
      {
        path: 'test',
        loadChildren: () => import('./tests/test.module').then(m => m.TestModule),
        canActivate: [AuthGuard],
        data: {
          role: ['superadmin', 'admin', 'facilitator']
        }
      },
      {
        path: 'section-tests',
        loadChildren: () => import('./section-Test/section-test.module').then(m => m.SectionTestModule),
        canActivate: [AuthGuard],
        data: {
          role: ['superadmin', 'admin', 'facilitator']
        }
      },
      
      {
        path: 'test',
        loadChildren: () => import('./tests/test.module').then(m => m.TestModule),
        canActivate: [AuthGuard],
        data: {
          role: ['superadmin', 'admin', 'facilitator']
        }
      },
      {
        path: 'section',
        loadChildren: () => import('./section/section.module').then(m => m.SectionModule),
        canActivate: [AuthGuard],
        data: {
          role: ['superadmin', 'admin', 'facilitator']
        }
      },
      {
        path: 'note',
        loadChildren: () => import('./notes/notes.module').then(m => m.NotesModule),
        canActivate: [AuthGuard],
        data: {
          role: ['superadmin', 'admin', 'facilitator']
        }
      },
      {
        path: 'accounts',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard],
        data: {
          role: ['superadmin', 'admin']
        }
      }
    ]
  },
  {
    path: 'ads',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'users',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard],
        data: {
          role: ['superadmin', 'admin']
        }
      },
      {
        path: '',
        loadChildren: () => import('./subject-unit/subject-unit.module').then(m => m.SubjectUnitModule)
      },
    ]
  },
  {
    path: '',
    loadChildren: () => import('./student/student.module').then(m => m.StudentModule),
    canActivate: [AuthGuard],
    data: {
      role: ['student', 'admin']
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Restore the last scroll position
    scrollPositionRestoration: "enabled"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
