import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  private loadingCount = 0
  constructor(spinnerService: NgxSpinnerService) {
    this.loading$.subscribe((loading)=>{
      if(loading){
        spinnerService.show();
      } else {
        spinnerService.hide();
      }
    })
  }
  set loading(val: boolean){
    if(val){
      this.loadingCount++;
    } else {
      this.loadingCount--;
    }
    this.loadingSubject.next(this.loadingCount != 0)
  }
}
