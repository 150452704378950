import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SubjectsListResponseModel } from '../models/subjectsResponse.model';
import { UnitsListReponseModel } from '../models/unitsResponse.model';
import { McqsResponseModel } from '../models/mcqsResponse.model';
import { TestResponseModel } from '../models/test.mdel';
import { NotesResponseModel } from './../models/NotesResponse.model';
import { FlagErrorResponseModel } from '../models/getFlagErrorResponse.model';
import { GetStudentByIdResponseMondel } from 'src/app/models/createUserResponse.model';
import { StartTestResponseModel } from '../models/startTestResponse.model';
import { CompleteTestResponseModel } from './../models/completeTestResponse.model';
import { TestHistoryByIDResponseModel } from '../models/testHistoryResponse.mode';
import { UserResponseModel } from '../models/userResponse.model';
import { LoadingService } from './loading.service';
import { SubUnitListReponseModel } from '../models/subUnitResponse.model';
import { testHistory } from '../student/student-account/student-account.component';
import { error } from 'console';
import { UserLoginResponseModel } from '../models/studentLoginResponse.model';
import { SectionTestResponse } from '../models/section-test-response.model';
import { NotesPackageResponseModel } from '../models/notesPackageResponse.model';
import { SectionResponse } from '../models/section-response.model';
import { SectionTestHistoryResponseModel } from '../models/sectionTestHistoryResponse.model';

@Injectable({
  providedIn: 'root'
})
export class DocShaalaService {

  constructor(private http: HttpClient, private loadingService: LoadingService) { }

  getAllMcqs(archived: boolean, pageDetails: { pageNumber: number, pageSize: number }): Observable<{ data: McqsResponseModel[], count: number }> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/mcqs?archived=${archived}&page=${pageDetails.pageNumber}&limit=${pageDetails.pageSize}`);
    return this.http.get<{ data: McqsResponseModel[], count: number }>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getFilteredMcqs(arcived: boolean, pageNumber: number, pageSize: number, subject?: any, unit?: any, searchString?: any): Observable<{ data: McqsResponseModel[], count: number }> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    let url = environment.ApiBaseUrl.concat(`/mcqs?archived=${arcived}&subject=${subject}&unit=${unit}&search=${searchString}&page=${pageNumber}&limit=${pageSize}`);
    return this.http.get<{ data: McqsResponseModel[], count: number }>(url, {
      headers: headers,
    })
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  createMcq(formData: any): Observable<McqsResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/mcqs`);
    return this.http.post<McqsResponseModel>(url, formData, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getMcqById(id: string, params = {}): Observable<McqsResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers,
      params
    };
    const url = environment.ApiBaseUrl.concat(`/mcqs/${id}`);
    return this.http.get<McqsResponseModel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  deleteMCQById(id: string): Observable<McqsResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };

    const bodyString = {
      archived: true
    };
    const url = environment.ApiBaseUrl.concat(`/mcqs/${id}?archived=false`);
    return this.http.put<McqsResponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  updateMcq(id: any, formData: any, archived?: boolean): Observable<McqsResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/mcqs/${id}?archived=${archived ? archived : false}`);
    return this.http.put<McqsResponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  uploadQuestionImage(id: any, file: any): Observable<McqsResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/mcqs/${id}/upload-question-image`);
    return this.http.put<McqsResponseModel>(url, file, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }
  // 
  getImage(id: string) {
    return this.http.get(`/mcqs/images/${id}`, { responseType: 'blob' });
  }
  // 

  uploadExplanationImage(id: any, file: any): Observable<McqsResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const uploadImaage = new FormData();
    uploadImaage.append('ExplanationImage', file);
    const url = environment.ApiBaseUrl.concat(`/mcqs/${id}/upload-Explanation-image`);
    return this.http.put<McqsResponseModel>(url, uploadImaage, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getAllSubjects(archived?: boolean): Observable<SubjectsListResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/subjects?archived=${archived ? archived : false}`);
    return this.http.get<SubjectsListResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getSubjectById(id: string): Observable<SubjectsListResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/subjects/${id}`);
    return this.http.get<SubjectsListResponseModel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getUnitById(id: string): Observable<UnitsListReponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/units/${id}`);
    return this.http.get<UnitsListReponseModel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getUnitBySubject(subjectId: string): Observable<UnitsListReponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/units?SubjectIds=${subjectId}`);
    return this.http.get<UnitsListReponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getAllUnits(subjectId?: string | null): Observable<UnitsListReponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url;
    if (subjectId) {
      url = environment.ApiBaseUrl.concat(`/units?archived=false&SubjectIds=${subjectId}`);
    } else {
      url = environment.ApiBaseUrl.concat(`/units?archived=false`);
    }

    return this.http.get<UnitsListReponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getAllUnitsBasedOnArchived(archived?: string | null): Observable<UnitsListReponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/units/search?archived=${archived ? archived : false}`);

    return this.http.get<UnitsListReponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  //subunit
  getSubUnitById(id: string): Observable<SubUnitListReponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/subunit/${id}`);
    return this.http.get<SubUnitListReponseModel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getSubUnitByUnit(unitId: string, subjectid: string): Observable<SubUnitListReponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = `${environment.ApiBaseUrl}/subunit?unitsId=${unitId}&subjectIds=${subjectid}`;
    return this.http.get<SubUnitListReponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getAllSubUnits(unitId?: string | null): Observable<SubUnitListReponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url;
    if (unitId) {
      url = environment.ApiBaseUrl.concat(`/subunit?archived=false&unitsId=${unitId}`);
    } else {
      url = environment.ApiBaseUrl.concat(`/subunit?archived=false`);
    }

    return this.http.get<SubUnitListReponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getAllSubUnitsBasedOnArchived(archived?: string | null): Observable<SubUnitListReponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/subunit/search?archived=${archived ? archived : false}`);

    return this.http.get<SubUnitListReponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getAllTests(archived: boolean): Observable<TestResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/tests?archived=${archived}`);
    return this.http.get<TestResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getFilteredTests(searchString: any): Observable<TestResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/tests?archived=false&search=${searchString}`);
    return this.http.get<TestResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getActiveTests(subjectId?: any, unitId?: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/tests?archived=false&activated=true&subject=${subjectId}&unit=${unitId}&nreq=questions`);
    return this.http.get<TestResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }
  // New SectionTest List
  getActiveSectionTests(subjectId?: any, unitId?: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/sectionTest?archived=false&activated=true&subject=${subjectId}&unit=${unitId}&nreq=questions`);
    return this.http.get<SectionTestResponse[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }
  // 

  // getTestById(id: string,q_limit:number,q_page:number): Observable<TestResponseModel> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     "Authorization": 'Bearer ' + localStorage.getItem('token')
  //   });
  //   const httpOptions = {
  //     headers: headers
  //   };
  //   const url = environment.ApiBaseUrl.concat(`/tests/${id}?&q_page=${q_page}&q_limit=${q_limit}`);
  //   return this.http.get<TestResponseModel>(url, httpOptions)
  //     .pipe(map((response) => {
  //       return response;
  //     }),
  //       catchError(error => this.handleError(error)));
  // }
   getTestById(id: string): Observable<TestResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/tests/${id}`);
    return this.http.get<TestResponseModel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getSectionTestById(id: string): Observable<SectionTestResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/sectionTest/${id}`);
    return this.http.get<SectionTestResponse>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  createTest(formData: any): Observable<TestResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/tests`);
    return this.http.post<TestResponseModel>(url, formData, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  deleteTestById(id: string): Observable<TestResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };

    const bodyString = {
      archived: true
    };
    const url = environment.ApiBaseUrl.concat(`/tests/${id}`);
    return this.http.put<TestResponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  // getSectionByIdForTest(sectionId: string): Observable<SectionResponse> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     "Authorization": 'Bearer'+ localStorage.getItem('token')
  //   });
  //   const httpOptions = {
  //     headers: headers
  //   };
  //   const url = environment.ApiBaseUrl.concat(`/section/${sectionId}`);
  //   return this.http.get<SectionResponse>(url, httpOptions)
  //     .pipe(
  //       catchError(error => this.handleError(error))
  //     );
  // }
  

  updateTest(id: any, formData: any): Observable<TestResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/tests/${id}`);
    return this.http.put<TestResponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getMcqsForTest(testId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/mcqs/${testId}/testmcq`);
    return this.http.get(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getFlagErrors(subject: string): Observable<FlagErrorResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/mcqs/flagerrors?flagError=true&subject=${subject ? subject : null}&select=subject,unit,questionId,createdAt,updatedAt,comments,lastModifiedBy`);
    return this.http.get<FlagErrorResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getAllNotes(archived: boolean, pdfType: any): Observable<NotesResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/notes?archived=${archived}&isPdfAvailableFor=${pdfType}`);
    return this.http.get<NotesResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getAllNotesPackage(archived: boolean): Observable<NotesPackageResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/notesPackage?archived=${archived}`);
    return this.http.get<NotesPackageResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getActiveNotes(subjectId: any, unitId?: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/notes?archived=false&activated=true&subject=${subjectId}&unit=${unitId}`);
    return this.http.get<NotesResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getActiveNotesPackage() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/notesPackage?archived=false&activated=true`);
    return this.http.get<NotesPackageResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getFilteredNotes(arcived: boolean, subject?: any, unit?: any, subunit?: any, searchString?: any): Observable<NotesResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/notes?archived=${arcived}&subject=${subject}&unit=${unit}&subunit=${subunit}&search=${searchString}`);
    return this.http.get<NotesResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getFilteredNotesPackage(arcived: boolean, searchString?: any): Observable<NotesPackageResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/notesPackage?archived=${arcived}&search=${searchString}`);
    return this.http.get<NotesPackageResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  createNote(formData: any): Observable<NotesResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/notes`);
    return this.http.post<NotesResponseModel>(url, formData, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  createNotesPackage(formData: any): Observable<NotesPackageResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/notesPackage`);
    return this.http.post<NotesPackageResponseModel>(url, formData, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getNoteById(id: string): Observable<NotesResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/notes/${id}`);
    return this.http.get<NotesResponseModel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getNotesPackageById(id: string): Observable<NotesPackageResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/notesPackage/${id}`);
    return this.http.get<NotesPackageResponseModel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getNoteSuggession() {
    const url = environment.ApiBaseUrl.concat(`/notes/suggession`);
    return this.http.get<NotesResponseModel>(url)
  }

  deleteNoteById(id: string): Observable<NotesResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };

    const bodyString = {
      archived: true
    };
    const url = environment.ApiBaseUrl.concat(`/notes/${id}?archived=false`);
    return this.http.put<NotesResponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  updateNote(id: any, formData: any, archived?: boolean): Observable<NotesResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/notes/${id}`);
    return this.http.put<NotesResponseModel>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  updateNotesPackage(id: any, formData: any, archived?: boolean): Observable<NotesPackageResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/notesPackage/${id}`);
    return this.http.put<NotesPackageResponseModel>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  subscribeTest(studentId: any, formData: any): Observable<GetStudentByIdResponseMondel> {
    console.log(612, studentId, formData)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/test/subscribe`);
    return this.http.put<GetStudentByIdResponseMondel>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  subscribeSectionTest(studentId: any, formData: any): Observable<GetStudentByIdResponseMondel> {
    console.log(612, studentId, formData)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/sectionTest/subscribe`);
    return this.http.put<GetStudentByIdResponseMondel>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getTestSuggession() {
    const url = environment.ApiBaseUrl.concat(`/tests/suggession`);
    return this.http.get<TestResponseModel>(url)
  }

  startTest(studentId: any, testId: any, formData: any): Observable<StartTestResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/test/${testId}/start`);
    return this.http.put<StartTestResponseModel>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  
  
  completeTest(studentId: any, testId: any, formData: any): Observable<CompleteTestResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/test/${testId}/end`);
    return this.http.put<CompleteTestResponseModel>(url, bodyString, httpOptions)
    .pipe(map((response) => {
      return response;
    }),
    catchError(error => this.handleError(error)));
  }
  
  // startSectionTest(studentId: any, testId: any, formData: any): Observable<StartTestResponseModel> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     "Authorization": 'Bearer ' + localStorage.getItem('token')
  //   });
  //   const httpOptions = {
  //     headers: headers
  //   };
  //   const bodyString = formData
  //   const url = environment.ApiBaseUrl.concat(`/students/${studentId}/sectionTest/${testId}/start`);
  //   return this.http.put<StartTestResponseModel>(url, bodyString, httpOptions)
  //     .pipe(map((response) => {
  //       return response;
  //     }),
  //       catchError(error => this.handleError(error)));
  // }
  
  // completeSectionTest(studentId: any, testId: any, formData: any): Observable<CompleteTestResponseModel> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     "Authorization": 'Bearer ' + localStorage.getItem('token')
  //   });
  //   const httpOptions = {
  //     headers: headers
  //   };
  //   const bodyString = formData
  //   const url = environment.ApiBaseUrl.concat(`/students/${studentId}/sectionTest/${testId}/end`);
  //   return this.http.put<CompleteTestResponseModel>(url, bodyString, httpOptions)
  //     .pipe(map((response) => {
  //       return response;
  //     }),
  //       catchError(error => this.handleError(error)));
  // }

  startSectionTest(studentId: any, testId: any, formData: any): Observable<StartTestResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/sectionTest/${testId}/start`);
    return this.http.put<StartTestResponseModel>(url, formData, { headers })
      .pipe(map((response) => response),
        catchError(error => this.handleError(error)));
  }

  completeSectionTest(studentId: any, testId: any, formData: any): Observable<CompleteTestResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/sectionTest/${testId}/end`);
    return this.http.put<CompleteTestResponseModel>(url, formData, { headers })
      .pipe(map((response) => response),
        catchError(error => this.handleError(error)));
  }

  // subs note////////////////////////////////////////////////////////////////////////////////////////////////
  subscribeNote(studentId: any, formData: any): Observable<any> {
    // Determine if it's a single or bulk subscription
    if (formData.noteIds && Array.isArray(formData.noteIds)) {
      return this.subscribeNoteBulk(studentId, formData.noteIds, formData);
    } else {
      return this.subscribeNoteSingle(studentId, formData.noteId, formData);
    }
  }

  private subscribeNoteSingle(studentId: any, noteId: any, formData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/note/subscribe`);
    const body = {
      noteId: noteId,
      subscriptionDate: formData.subscriptionDate,
      subscriptionId: formData.subscriptionId ? formData.subscriptionId : null,
      amountPaid: formData.amountPaid
    };
    return this.http.put<any>(url, body, httpOptions).pipe(
      map(response => response),
      catchError(error => this.handleError(error))
    );
  }

  private subscribeNoteBulk(studentId: any, noteIds: any[], formData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/note/subscribe`);
    const body = {
      noteIds: noteIds,
      subscriptionDate: formData.subscriptionDate,
      amountPaid: formData.amountPaid
    };
    return this.http.put<any>(url, body, httpOptions).pipe(
      map(response => response),
      catchError(error => this.handleError(error))
    );
  }

// unsub notes //////////////////////////////////////////////////////////////////
  unsubscribeNote(studentId: any, formData: any): Observable<any> {
    // Determine if it's a single or bulk unsubscription
    if (formData.noteIds && Array.isArray(formData.noteIds)) {
      return this.unsubscribeNoteBulk(studentId, formData.noteIds, formData);
    } else {
      return this.unsubscribeNoteSingle(studentId, formData.noteId, formData);
    }
  }

  private unsubscribeNoteSingle(studentId: any, noteId: any, formData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/note/unsubscribe`);
    const body = {
      noteId: noteId
    };
    return this.http.put<any>(url, body, httpOptions).pipe(
      map(response => response),
      catchError(error => this.handleError(error))
    );
  }

  private unsubscribeNoteBulk(studentId: any, noteIds: any[], formData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/note/unsubscribe`);
    const body = {
      noteIds: noteIds
    };
    return this.http.put<any>(url, body, httpOptions).pipe(
      map(response => response),
      catchError(error => this.handleError(error))
    );
  }

  // unsub notes package //////////////////////////////////////////////////////////////////////////
  unsubscribeNotesPackage(studentId: any, formData: any): Observable<any> {
    // Determine if it's a single or bulk unsubscription
    if (formData.noteIds && Array.isArray(formData.noteIds)) {
      return this.unsubscribeNotesPackageBulk(studentId, formData.noteIds, formData);
    } else {
      return this.unsubscribeNotesPackageSingle(studentId, formData.noteId, formData);
    }
  }

  private unsubscribeNotesPackageSingle(studentId: any, noteId: any, formData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/notesPackage/unsubscribe`);
    const body = {
      noteId: noteId
    };
    return this.http.put<any>(url, body, httpOptions).pipe(
      map(response => response),
      catchError(error => this.handleError(error))
    );
  }

  private unsubscribeNotesPackageBulk(studentId: any, noteIds: any[], formData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/students/${studentId}/notesPackage/unsubscribe`);
    const body = {
      noteIds: noteIds
    };
    return this.http.put<any>(url, body, httpOptions).pipe(
      map(response => response),
      catchError(error => this.handleError(error))
    );
  }



    ////////////////////////////////// unsub test //////////////////////////////////////////////////////////////////////////

    unsubscribeTests(studentId: any, formData: any): Observable<any> {
      // Determine if it's a single or bulk unsubscription
      if (formData.testIds && Array.isArray(formData.testIds)) {
        return this.unsubscribeTestsBulk(studentId, formData.testIds, formData);
      } else {
        return this.unsubscribeTestsSingle(studentId, formData.testIds, formData);
      }
    }
  
    private unsubscribeTestsSingle(studentId: any, testId: any, formData: any): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      });
      const httpOptions = {
        headers: headers
      };
      const url = environment.ApiBaseUrl.concat(`/students/${studentId}/test/unsubscribe`);
      const body = {
        testId: testId
      };
      return this.http.put<any>(url, body, httpOptions).pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
    }
  
    private unsubscribeTestsBulk(studentId: any, testIds: any[], formData: any): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      });
      const httpOptions = {
        headers: headers
      };
      const url = environment.ApiBaseUrl.concat(`/students/${studentId}/test/unsubscribe`);
      const body = {
        testIds: testIds
      };
      return this.http.put<any>(url, body, httpOptions).pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
    }
    

//**************************************************************************************//
//=========================== unsub section test  ============================//
//********************************************************************************* */
unsubscribeSectionTests(studentId: any, formData: any): Observable<any> {
  // Determine if it's a single or bulk unsubscription
  if (formData.testIds && Array.isArray(formData.testIds)) {
    return this.unsubscribeSectionTestsBulk(studentId, formData.testIds, formData);
  } else {
    return this.unsubscribeSectionTestsSingle(studentId, formData.testIds, formData);
  }
}

private unsubscribeSectionTestsSingle(studentId: any, testId: any, formData: any): Observable<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token')
  });
  const httpOptions = {
    headers: headers
  };
  const url = environment.ApiBaseUrl.concat(`/students/${studentId}/sectionTest/unsubscribe`);
  const body = {
    testId: testId
  };
  return this.http.put<any>(url, body, httpOptions).pipe(
    map(response => response),
    catchError(error => this.handleError(error))
  );
}

private unsubscribeSectionTestsBulk(studentId: any, testIds: any[], formData: any): Observable<any> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token')
  });
  const httpOptions = {
    headers: headers
  };
  const url = environment.ApiBaseUrl.concat(`/students/${studentId}/sectionTest/unsubscribe`);
  const body = {
    testIds: testIds
  };
  return this.http.put<any>(url, body, httpOptions).pipe(
    map(response => response),
    catchError(error => this.handleError(error))
  );
}


  

  getTestHistoryById(id: string, params?: {[k:string]: string}): Observable<TestHistoryByIDResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers,
      params
    };
    const url = environment.ApiBaseUrl.concat(`/testhistory/${id}`);
    return this.http.get<TestHistoryByIDResponseModel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getSectionTestHistoryById(id: string, params?: {[k:string]: string}): Observable<SectionTestHistoryResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers,
      params
    };
    const url = environment.ApiBaseUrl.concat(`/sectionTestHistory/${id}`);
    return this.http.get<SectionTestHistoryResponseModel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  updateSubject(id: any, formData: any, archived?: boolean): Observable<SubjectsListResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/subjects/${id}?archived=${archived ? archived : false}`);
    return this.http.put<SubjectsListResponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  updateUnit(id: any, formData: any, archived?: boolean): Observable<UnitsListReponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/units/${id}?archived=${archived ? archived : false}`);
    return this.http.put<UnitsListReponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }
  updateSubUnit(id: any, formData: any, archived?: boolean): Observable<SubUnitListReponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/subunit/${id}?archived=${archived ? archived : false}`);
    return this.http.put<SubUnitListReponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  deleteSubjectById(id: string): Observable<SubjectsListResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };

    const bodyString = {
      archived: true
    };
    const url = environment.ApiBaseUrl.concat(`/subjects/${id}?archived=false`);
    return this.http.put<SubjectsListResponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  deleteUnitById(id: string): Observable<UnitsListReponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };

    const bodyString = {
      archived: true
    };
    const url = environment.ApiBaseUrl.concat(`/units/${id}?archived=false`);
    return this.http.put<UnitsListReponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  deleteSubUnitById(id: string): Observable<SubUnitListReponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };

    const bodyString = {
      archived: true
    };
    const url = environment.ApiBaseUrl.concat(`/subunit/${id}?archived=false`);
    return this.http.put<SubUnitListReponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  createSubject(formData: any): Observable<SubjectsListResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/subjects`);
    return this.http.post<SubjectsListResponseModel>(url, formData, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  createUnit(formData: any): Observable<UnitsListReponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/units`);
    return this.http.post<UnitsListReponseModel>(url, formData, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  createSubUnit(formData: any): Observable<SubUnitListReponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/subunit`);
    console.log(formData, '838')
    return this.http.post<SubUnitListReponseModel>(url, formData, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getAllUsers(archived?: boolean): Observable<UserResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url;
    if (archived !== undefined) {
      url = environment.ApiBaseUrl.concat(`/users?archived=${archived}`);
    } else {
      url = environment.ApiBaseUrl.concat(`/users`);
    }
    return this.http.get<UserResponseModel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getUsersById(userId: string): Observable<UserResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    let url = environment.ApiBaseUrl.concat(`/users/${userId}`);

    return this.http.get<UserResponseModel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  updateUser(userId: string, formData: any, archived?: boolean): Observable<UserResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/users/${userId}`);
    return this.http.put<UserResponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  deleteUserById(id: string): Observable<UserResponseModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };

    const bodyString = {
      archived: true
    };
    const url = environment.ApiBaseUrl.concat(`/users/${id}`);
    return this.http.put<UserResponseModel[]>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  // handleError(error: HttpErrorResponse) {
  //   let err: any;
  //   if (error.error.length >= 0) {
  //     err = error.error;
  //   } else {
  //     err = error;
  //   }
  //   return throwError(err);
  // }

  handleError(error: HttpErrorResponse) {
    let err: any;
    if (error.error.length >= 0) {
      err = error.error;
    } else {
      err = error;
    }
    return throwError(err);
  }

  getMcqsByName(
    archived: boolean,
    name: string
  ): Observable<{ data: McqsResponseModel[]; count: number }> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    });
    const httpOptions = {
      headers: headers,
    };
    const url = environment.ApiBaseUrl.concat(
      `/mcqs?archived=${archived}&name=${name}`
    );
    return this.http
      .get<{ data: McqsResponseModel[]; count: number }>(url, httpOptions)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getRankByTest(id: any, testId: any): Observable<any> {
    const url = environment.ApiBaseUrl.concat(`/testhistory/ranking/${id}/${testId}`);
    return this.http.get<any>(url);
  }

  getStudentList(): Observable<GetStudentByIdResponseMondel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/students`);
    return this.http.get<GetStudentByIdResponseMondel[]>(url, httpOptions)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(error => this.handleError(error))
      );
  }
  

}
