<div class="content auth-container">
    <div class="header navbar navbar-expand-lg">
        <div class="logo">
            <a class="navbar-brand" href="#">DocShaala</a>
        </div>
    </div>
    <div class="content-body">
        <div class="container">
            <h2 class="page-header">Reset your password</h2>
            <div class="d-flex justify-content-center py-5">
                <form>
                    <div class="form-group row">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                            <input [(ngModel)]="email" name="email" type="text" class="form-control" id="staticEmail"
                                placeholder="example@mail.com">
                        </div>
                    </div>
                    <div class="text-center">
                        <button (click)="resetPassword()" class="btn btn-primary">Reset Password</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>