import { Component, OnInit } from '@angular/core';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { LoginService } from './../../services/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  // @ts-ignore: Object is possibly 'null'.
  email: string;

  constructor(private globalState: GlobalStateService, private loginService: LoginService) { }

  ngOnInit(): void {
  }

  resetPassword() {
    this.globalState.showLoader.show();
    this.loginService.forgetPassword(this.email).subscribe(resp => {
      this.globalState.showLoader.hide();
      this.globalState.showMessage('success', resp.data, '');
    }, err => {
      this.globalState.showLoader.hide();
    });
  }

}
