<div class="content"  >
    <!-- *ngIf="currentUserRole === 'student'" -->
    <app-header></app-header>
    <div class="content-body">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
</div>

<!-- <ng-container>
    *ngIf="currentUserRole !== 'student'"
<div class="">
    <div class="row">
        <div  [ngClass]="openSidebar ? 'col-2' : 'col-1'" class="">
            <div class="sidebar_fixed"  >
                <img class="logo_img" src="../../../assets/images/logo.png" alt="">
                <div class="m-3 cursor_pointer"><span><i class="fa fa-home sidebar_icons" aria-hidden="true"></i></span><span class="ms-3"  *ngIf="openSidebar">Dashboard</span></div>
                <div [routerLink]="['/ads/users/aplSetting']" class="m-3 cursor_pointer d-flex align-items-center"><span><i class="fa fa-bars sidebar_icons" aria-hidden="true"></i></span>
                    <span  class="ms-3"><a *ngIf="openSidebar" class="nav-link" 
                        [ngClass]="globalState.currentLayout == 'content' ? 'active' : ''"
                        href="javascript:void(0)">Content Management</a></span>
                </div>
                <div [routerLink]="['/ads/users/Course']" class="m-3 cursor_pointer d-flex align-items-center"><span><i class="fa fa-book sidebar_icons" aria-hidden="true"></i></span><span class="ms-3"><a *ngIf="openSidebar" class="nav-link" 
                    [ngClass]="globalState.currentLayout == 'course' ? 'active' : ''"
                    href="javascript:void(0)">Course</a></span></div>
                <div [routerLink]="['/ads/subject-list']" class="m-3 cursor_pointer d-flex align-items-center"><span><i class="fa fa-pencil-square-o sidebar_icons" aria-hidden="true"></i></span><span class="ms-3"> <a *ngIf="openSidebar" class="nav-link" 
                    [ngClass]="globalState.currentLayout == 'subject' ? 'active' : ''"
                    href="javascript:void(0)">Subjects</a></span></div>
                <div class="m-3 cursor_pointer d-flex align-items-center" [routerLink]="['/ads/unit-list']"><span><i class="fa fa-pencil-square-o sidebar_icons" aria-hidden="true"></i></span><span class="ms-3"> <a *ngIf="openSidebar" class="nav-link" 
                    [ngClass]="globalState.currentLayout == 'unit' ? 'active' : ''"
                    href="javascript:void(0)">Units</a></span></div>
                    <div [routerLink]="['/ads/subunit-list']" class="m-3 cursor_pointer d-flex align-items-center"><span><i class="fa fa-pencil-square-o sidebar_icons" aria-hidden="true"></i></span> <span class="ms-3"> <a *ngIf="openSidebar" class="nav-link" 
                        [ngClass]="globalState.currentLayout == 'subunit' ? 'active' : ''"
                        href="javascript:void(0)">Subunits</a></span></div>
                <div [routerLink]="['/ads/users/list']" class="m-3 cursor_pointer d-flex align-items-center"><span><i class="fa fa-user-circle-o sidebar_icons" aria-hidden="true"></i></span><span class="ms-3"><a *ngIf="openSidebar" class="nav-link" 
                    [ngClass]="globalState.currentLayout == 'account' ? 'active' : ''"
                    href="javascript:void(0)">Users</a></span></div>
                <div [routerLink]="['/ads/users/student-list']" class="m-3 cursor_pointer d-flex align-items-center"><span><i class="fa fa-graduation-cap sidebar_icons" aria-hidden="true"></i></span><span class="ms-3"><a *ngIf="openSidebar" class="nav-link" 
                    [ngClass]="globalState.currentLayout == 'account' ? 'active' : ''"
                    href="javascript:void(0)">Students</a></span></div>
                <div (click)="logout()"  class="m-3 cursor_pointer d-flex align-items-center"><span><i class="fa fa-sign-out sidebar_icons" aria-hidden="true"></i></span><span class="ms-3"><a *ngIf="openSidebar" class="nav-link" href="javaScript:void(0);">Logout</a></span></div>
                <span class="sidebar_icon_open" *ngIf="!openSidebar" [ngStyle]="{ 'left': openSidebar ? '200px' : '70px' }"><mat-icon  (click)="openSidebar=true">keyboard_arrow_right</mat-icon></span>
                <span class="sidebar_icon_close" *ngIf="openSidebar" ><mat-icon (click)="openSidebar=false">keyboard_arrow_left</mat-icon></span>
            </div>
        </div>
      
        <div class="mt-5" [ngClass]="openSidebar ? 'col-10' : 'col-11'">
           <router-outlet></router-outlet>
          
        </div>
    </div>
  
</div></ng-container> -->
  