<div class="content auth-container">
    <div class="header navbar navbar-expand-lg">
        <div class="logo">
            <a class="navbar-brand" href="#">DocShaala</a>
        </div>
    </div>
    <div class="content-body">
        <div class="container">
            <h2 class="page-header">Set New Password</h2>
            <div class="d-flex justify-content-center py-2">
                <form [formGroup]="passwordForm">
                    <div class="form-group row">
                        <label for="newpassword" class="col-sm-5 col-form-label">New Password</label>
                        <div class="col-sm-7">
                            <input
                                [ngClass]="{ 'is-invalid': (PasswordFormControl.password.touched && PasswordFormControl.password.errors) || (submitted && PasswordFormControl.password.errors) }"
                                formControlName="password" type="password" class="form-control" id="newpassword"
                                placeholder="New Password">
                            <div
                                *ngIf="(PasswordFormControl.password.touched && PasswordFormControl.password.errors) || (submitted && PasswordFormControl.password.errors)">
                                <div class='text-danger' *ngIf="PasswordFormControl.password.errors.required">
                                    Password is required</div>
                                <div class='text-danger' *ngIf="PasswordFormControl.password.errors.pattern">
                                    Passwords must be at least 8 characters in length contain at least one lower-case
                                    letter,
                                    one upper-case letter, and one number.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="password" class="col-sm-5 col-form-label">Confirm Password</label>
                        <div class="col-sm-7">
                            <input
                                [ngClass]="{ 'is-invalid': (PasswordFormControl.confirmpassword.touched && PasswordFormControl.confirmpassword.errors) || (submitted && PasswordFormControl.confirmpassword.errors) }"
                                formControlName="confirmpassword" type="password" class="form-control" id="password"
                                placeholder="Confirm Password">
                            <div
                                *ngIf="(PasswordFormControl.confirmpassword.touched && PasswordFormControl.confirmpassword.errors) || (submitted && PasswordFormControl.confirmpassword.errors)">
                                <div class='text-danger' *ngIf="PasswordFormControl.confirmpassword.errors.required">
                                    Confirm Password is required</div>
                                <div class='text-danger' *ngIf="PasswordFormControl.confirmpassword.errors.mustMatch">
                                    Password's must match</div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button (click)="changePassword()" class="btn btn-primary">Set New Password</button>

                    </div>
                </form>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>