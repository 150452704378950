import { Component, OnInit } from '@angular/core';
import { GlobalStateService } from './../../services/global-state.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
  openSidebar:boolean = false
   // @ts-ignore: Object is possibly 'null'.
  currentUserRole: string = localStorage.getItem('roles');
  constructor(private globalState: GlobalStateService) { }

  ngOnInit(): void {
    this.globalState.currentLayout = 'MCQ';
  }

}
