<div class="content unauthorized-container">
    <div class="header navbar navbar-expand-lg">
        <div class="logo">
            <a class="navbar-brand" href="#">DocShaala</a>
        </div>
        <div>
            <a [routerLink]="['/login']">Login</a>
        </div>
    </div>
    <div class="content-body">
        <div class="container">
            <div class="d-block text-center">
                <div class="py-3">
                    <mat-icon>warning</mat-icon>
                </div>
                <div>
                    <h1>403 - Restricted Access!</h1>
                </div>
            </div>
            <div class="text-center pt-4">
                <button (click)="goBack()" class="btn btn-primary">Go Back</button>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>