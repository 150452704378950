import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalStateService } from './../../services/global-state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // @ts-ignore: Object is possibly 'null'.
  isLoggedIn!: string = localStorage.getItem('isLoggedIn');
  // @ts-ignore: Object is possibly 'null'.
  currentUserRole: string = localStorage.getItem('roles');

  constructor(public globalState: GlobalStateService,
    private router: Router) { }

  ngOnInit(): void {
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
