import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { LoginService } from './../../services/login.service';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm!: UntypedFormGroup;
  submitted = false;
  isInvalidCredentials = false;
  isLoggedIn = localStorage.getItem('isLoggedIn');
  isPrice = localStorage.getItem('price');
  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private userService: UserService,
    private globalState: GlobalStateService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{7,})\S$/
          ),
        ]),
      ],
    });
    // if (this.isLoggedIn == 'true') {
    //   localStorage.clear();
    // }
  }

  get LoginFormControl() {
    return this.loginForm.controls;
  }

  login() {
    console.log('price', this.isPrice);
    this.globalState.showLoader.show();
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.globalState.showLoader.hide();
      this.globalState.errorWarnings();
      return;
    } else {
      this.loginService.login(this.loginForm.value).subscribe(
        (resp) => {
          localStorage.setItem('token', resp.token);
          localStorage.setItem('isLoggedIn', 'true');
          const getUserId = this.globalState.decodeToken(resp.token).id;
          const getCurrentUserRole = this.globalState.decodeToken(
            resp.token
          ).role;
          localStorage.setItem('roles', getCurrentUserRole);
          if (getCurrentUserRole == 'admin') {
            this.router.navigate(['/ads/users/list']);
          } else if (getCurrentUserRole == 'facilitator') {
            this.router.navigate(['/fs/test/list']);
            this.globalState.showLoader.hide();
          } else if (getCurrentUserRole == 'superadmin') {
            this.router.navigate(['/sad/accounts/list']);
            this.globalState.showLoader.hide();
          } else if (getCurrentUserRole == 'student' && !this.isPrice) {
            this.userService.getCurrentUser(getUserId).subscribe(
              (userResponse) => {
                console.log(
                  'current User Id',
                  userResponse,
                  userResponse[0]._id
                );
                localStorage.setItem('userId', userResponse[0]._id);
                this.router.navigate(['/profile']);
                this.globalState.showLoader.hide();
              },
              (err) => {
                this.globalState.invokeGenericErrorMessage();
              }
            );
          } else if (getCurrentUserRole == 'student' && this.isPrice) {
            console.log('price', this.isPrice);
            this.userService.getCurrentUser(getUserId).subscribe(
              (userResponse) => {
                console.log(
                  'current User Id',
                  userResponse,
                  userResponse[0]._id
                );
                localStorage.setItem('userId', userResponse[0]._id);
                this.router.navigate(['/cart'], {
                  queryParams: { price: this.isPrice, userId: getUserId },
                });
                this.globalState.showLoader.hide();
              },
              (err) => {
                this.globalState.invokeGenericErrorMessage();
              }
            );
          }
        },
        (err) => {
          this.isInvalidCredentials = true;
          this.globalState.showLoader.hide();
        }
      );
    }
  }

  goToForgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }

  ngOnDestroy(): void {
    localStorage.removeItem('price');
  }

  goToSignUp(){
    // this.router.navigate(['/signup']);  
    console.log("price", this.isPrice)
    this.router.navigate(['/signup'], { queryParams: { price: this.isPrice } });
    }
  }
