import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

   // Exclude MCQ-related URLs from loader interceptor
   if (req.params.get('hideLoader')) {
    return next.handle(req);
  }

    this.loadingService.loading = true
    console.log(req)
    return next.handle(req).pipe(
      finalize(() => {
        this.loadingService.loading = false;
        console.log('final')
      })
    );
  }
}
