<div *ngIf="globalState.showWarningAlert" class="alert custom-alert box-shadow"
    [ngClass]="(globalState.customAlert ? (globalState.customAlertClass +' '+ globalState.warningClass) : globalState.warningClass)"
    role="alert">
    <div class="d-flex justify-content-between">
        <div>
            <strong>{{globalState.warningTitle}}</strong>
            <span [innerHTML]="globalState.warningMessage"></span>
            <ng-container *ngIf="globalState.isNextLine">
                <p class="mb-0"><em>{{globalState.warningNextLine}}</em></p>
            </ng-container>
        </div>
        <div class="hover-pointer">
            <mat-icon (click)="closeAlert()">close</mat-icon>
        </div>
    </div>
</div>