<div class="container">
    <div class="d-flex justify-content-between align-items-center">
        <h2 class="mb-0 page-header">Test Flag Errors</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active hover-pointer" aria-current="page">Flag Errors</li>
            </ol>
        </nav>
    </div>
    <hr>
    <div class="form-group">
        <div class="row mb-3">
            <!-- <div class="col-12 col-xs-7 col-md-9 col-lg-10 mb-2 mb-xs-0 mb-md-0 mb-lg-0"></div> -->
            <div class="col-12">
                <label>Choose Subject</label>
                <select (change)="getChangedSubject($event.target.value)" [(ngModel)]="subject" class="form-control">
                    <option value=''>All</option>
                    <option *ngFor="let subject of subjectList" [value]="subject._id">{{subject.name}}</option>
                </select>
            </div>
            <!-- <div class="text-right col-12 col-xs-5 col-md-3 col-lg-2">
                <button (click)="searchFlagsErrors()" class="btn btn-block btn-primary">Search</button>
            </div> -->
        </div>
    </div>
    <div class="my-2">
        <label class="ml-2 text-16">{{selectedSubjectName | titlecase}}: <span class="text-danger">{{flagErrors.length}}
                Flags</span></label>
    </div>
    <div class="d-flex justify-content-center" *ngIf="flagErrors.length === 0; else showData">
        <h3>No MCQs are flagged for {{selectedSubjectName}}</h3>
    </div>
    <ng-template #showData>
        <div class="table-responsive">
            <table class="table table-stripped">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Subject</th>
                        <th>Unit</th>
                        <th>Question Id</th>
                        <th>Marked By</th>
                        <th>Comments</th>
                        <th>Last Updated</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let flag of flagErrors; let i = index">
                        <td>{{flag.createdAt | date: 'dd MMM YYYY'}}</td>
                        <td>{{flag.subjectName}}</td>
                        <td>{{flag.unitName}}</td>
                        <td>{{flag.name}}</td>
                        <td>{{flag.lastModifiedBy}}</td>
                        <td>{{flag.comments}}</td>
                        <td>{{flag.updatedAt | date: 'dd MMM YYYY'}}</td>
                        <td>
                            <mat-icon (click)="goToMCQEdit(flag._id)" class="mr-2 hover-pointer">create
                            </mat-icon>
                            <mat-icon class="hover-pointer" (click)="goToMCQDetail(flag._id)">remove_red_eye</mat-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-template>
</div>