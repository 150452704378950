
<nav class="navbar backdrop">
    <div class="navbar-container container">
        <input type="checkbox" name="" id="" />
        <div class="hamburger-lines">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
        </div>
        <ul class="menu-items">

            <ng-container *ngIf="currentUserRole == 'admin'">
                <li>
                    <a class="nav-link" [routerLink]="['/ads/users/aplSetting']"
                        [ngClass]="globalState.currentLayout == 'content' ? 'active' : ''"
                        href="javascript:void(0)">Content Management</a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="['/ads/users/Course']"
                        [ngClass]="globalState.currentLayout == 'course' ? 'active' : ''"
                        href="javascript:void(0)">Course</a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="['/ads/subject-list']"
                        [ngClass]="globalState.currentLayout == 'subject' ? 'active' : ''"
                        href="javascript:void(0)">Subjects</a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="['/ads/unit-list']"
                        [ngClass]="globalState.currentLayout == 'unit' ? 'active' : ''"
                        href="javascript:void(0)">Units</a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="['/ads/subunit-list']"
                        [ngClass]="globalState.currentLayout == 'subunit' ? 'active' : ''"
                        href="javascript:void(0)">Subunits</a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="['/ads/users/list']"
                        [ngClass]="globalState.currentLayout == 'account' ? 'active' : ''"
                        href="javascript:void(0)">Users</a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="['/ads/users/student-list']"
                        [ngClass]="globalState.currentLayout == 'account' ? 'active' : ''"
                        href="javascript:void(0)">Students</a>
                </li>
            </ng-container>
            <ng-container *ngIf="currentUserRole !== 'student' && currentUserRole !== 'admin'">
                <li *ngIf="currentUserRole !== 'student'">
                    <a class="nav-link" [routerLink]="['/fs/mcq/list']"
                        [ngClass]="globalState.currentLayout == 'mcq' ? 'active' : ''" href="javascript:void(0)">MCQ</a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="['/fs/test/list']"
                        [ngClass]="globalState.currentLayout == 'test' ? 'active' : ''" href="javascript:void(0)">Test
                        Series</a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="['/fs/section-tests/list']"
                        [ngClass]="globalState.currentLayout == 'section-tests' ? 'active' : ''"
                        href="javascript:void(0)">Test Series (sections)</a>
                </li>
                <li *ngIf="currentUserRole !== 'student'">
                    <a class="nav-link" [routerLink]="['/fs/note/list']"
                        [ngClass]="globalState.currentLayout == 'note' ? 'active' : ''"
                        href="javascript:void(0)">Notes</a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="['/fs/test-flag-errors']"
                        [ngClass]="globalState.currentLayout == 'flag' ? 'active' : ''" href="javascript:void(0)">Test
                        Flag Errors</a>
                </li>
            </ng-container>
            <li>
                <div class="context-menu-item">
                    <a (click)="logout()" href="javaScript:void(0);">Logout</a>
                </div>
            </li>
        </ul>
        <a [routerLink]="currentUserRole === 'admin' ? '/ads/users/list' : '/fs/mcq/list'" class="brand-logo">
            <img src="../../../../../assets/images/logo.png" />
        </a>
    </div>
</nav>