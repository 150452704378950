import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isLoggedIn: any;
  token: any;

  constructor(private router: Router, private loginService: LoginService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.isLoggedIn = localStorage.getItem('isLoggedIn');
    this.token = localStorage.getItem('token');
    if (this.isLoggedIn == undefined) {
      this.router.navigate(['/login']);
      return false;
    }
    const jsonToken = this.decodeToken(this.token);
    const expireyTime = jsonToken.exp * 1000;
    const todayTimeDate = new Date().getTime();
    if (expireyTime < todayTimeDate) {
      this.router.navigate(['/unauthorized']);
      return false;
    }
    if (this.checkUserLogin(route, state.url)) {
      return true;
    }
    this.router.navigate(['/unauthorized']);
    return false;
  }

  decodeToken(token: any) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const jsonToken = JSON.parse(jsonPayload);
    return jsonToken;
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.isLoggedIn == 'true') {
      const userRole = this.decodeToken(this.token);
      if (route.data.role && route.data.role.indexOf(userRole.role) > -1) {
        return true;
      }
      return false;
    }
    return false;
  }

}
