import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FlagErrorResponseModel } from '../models/getFlagErrorResponse.model';
import { SubjectsListResponseModel } from '../models/subjectsResponse.model';
import { UnitsListReponseModel } from '../models/unitsResponse.model';
import { DocShaalaService } from '../services/docshaala.service';
import { GlobalStateService } from './../services/global-state.service';

@Component({
  selector: 'app-flag-errors',
  templateUrl: './flag-errors.component.html',
  styleUrls: ['./flag-errors.component.scss']
})
export class FlagErrorsComponent implements OnInit {

  subject = '';
  selectedSubjectName = 'All';
  flagErrors: FlagErrorResponseModel[] = [];
  subjectList: SubjectsListResponseModel[] = [];
  unitsList: UnitsListReponseModel[] = [];

  constructor(private globalState: GlobalStateService, private flagService: DocShaalaService, private router: Router) { }

  ngOnInit(): void {
    this.globalState.showLoader.show();
    this.globalState.currentLayout = 'flag';
    this.flagService.getAllSubjects().subscribe(resp => {
      this.subjectList = resp;
      this.getSelectSubjectUnits(null);
    }, err => {
      this.globalState.invokeGenericErrorMessage();
    })
  }

  getChangedSubject(subjectId: string) {
    const subjectDetails = this.subjectList.filter(item => item._id == subjectId)[0];
    this.subject = subjectDetails._id;
    this.selectedSubjectName = subjectDetails.name;
    this.searchFlagsErrors()
  }

  getSelectSubjectUnits(subjectId: string | null) {
    this.flagService.getAllUnits(subjectId ? subjectId : null).subscribe(resp => {
      this.unitsList = resp;
      this.searchFlagsErrors();
    }, err => {
      this.globalState.invokeGenericErrorMessage();
    });
  }

  getUnitName(unitId: string) {
    const unitDetails = this.unitsList.filter(item => item._id == unitId)[0]
    return unitDetails.name;
  }

  searchFlagsErrors() {
    this.flagService.getFlagErrors(this.subject).subscribe(resp => {
      resp.forEach(item => {
        item.unitName = this.getUnitName(item.unit);
        item.subjectName = this.subjectList.filter(subjItem => subjItem._id == item.subject)[0].name;
      });
      this.flagErrors = resp;
      this.globalState.showLoader.hide();
    }, err => {
      this.globalState.invokeGenericErrorMessage();
    });
  }

  goToMCQEdit(mcqId: any) {
    this.router.navigate([`/fs/mcq/${mcqId}/edit`], { queryParams: { flagError: true } })
  }

  goToMCQDetail(mcqId: any) {
    this.router.navigate([`/fs/mcq/${mcqId}/detail`], { queryParams: { flagError: true } })
  }

}
