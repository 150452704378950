import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { LoginService } from './../../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/app/services/validators/mustMatch.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  // @ts-ignore: Object is possibly 'null'.
  passwordForm: UntypedFormGroup;
  // @ts-ignore: Object is possibly 'null'.
  resetToken: string;
  goToLoginBtn = false;
  submitted = false;

  constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private globalState: GlobalStateService,
    private login: LoginService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.resetToken = params['resettoken'];
    });
    this.passwordForm = this.fb.group({
      password: ['', Validators.compose([Validators.required, Validators.pattern(/^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{7,})\S$/)])],
      confirmpassword: ['', Validators.compose([Validators.required, Validators.pattern(/^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{7,})\S$/)])],
    }, { validator: MustMatch('password', 'confirmpassword') });
  }

  get PasswordFormControl() {
    return this.passwordForm.controls;
  }

  changePassword() {
    this.globalState.showLoader.show();
    this.submitted = true;
    if (this.passwordForm.invalid) {
      this.globalState.showLoader.hide();
      this.globalState.errorWarnings();
      return;
    }
    const reqObj = {
      password: this.PasswordFormControl.confirmpassword.value
    }
    this.login.resetPassword(reqObj, this.resetToken).subscribe(resp => {
      this.globalState.showLoader.hide();
      this.goToLoginBtn = true;
      this.globalState.showMessage('success', 'Password changed successfully!', '');
    }, err => {
      this.globalState.showLoader.hide();
      this.globalState.showMessage('error', err.error.error, '');
    });
  }
}
