<div class="login-bg"></div>
<div class="login-bg app-bg"></div>
<div class="d-flex justify-content-center login-card h-100">
    <div class="user_card">
        <div class="d-flex justify-content-center">
            <div class="brand_logo_container">
                <!-- <img [src]="'/assets/images/logo.png'" alt="Logo"> -->
                <a [routerLink]="['/']" style="cursor: pointer;">
                    <img [src]="'/assets/images/logo.png'" alt="Logo">
                </a>
            </div>
        </div>
        <div [ngClass]="isInvalidCredentials ? 'invalid-login-form' : '' "
            class="d-flex justify-content-center flex-column login-form-container">
            <div *ngIf="isInvalidCredentials"
                class="alert alert-danger hover-pointer align-items-center d-flex justify-content-between" role="alert">
                Invalid Credentials
                <div class="font-12">
                    <mat-icon (click)="isInvalidCredentials = !isInvalidCredentials">close</mat-icon>
                </div>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="login()">
                <div class="mb-3">
                    <div class="input-group">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <mat-icon>person</mat-icon>
                            </span>
                        </div>
                        <input
                            [ngClass]="(LoginFormControl.email.touched && LoginFormControl.email.errors?.required ) ? 'is-invalid': ((submitted && LoginFormControl.email.errors?.required ) ? 'is-invalid' : '')"
                            formControlName="email" type="text" class="form-control input_user" placeholder="Email">
                    </div>
                    <div
                        *ngIf="(LoginFormControl.email.touched && LoginFormControl.email.errors) || (submitted && LoginFormControl.email.errors)">

                        <div class='text-danger' *ngIf="LoginFormControl.email.errors.pattern">
                            Email is invalid
                        </div>
                        <div class='text-danger' *ngIf="LoginFormControl.email.errors.required">
                            Email is required
                        </div>
                    </div>
                </div>
                <div class="input-group mb-2">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <mat-icon>lock</mat-icon>
                        </span>
                    </div>
                    <input
                        [ngClass]="(LoginFormControl.password.touched && LoginFormControl.password.errors?.required) ? 'is-invalid': ((submitted && LoginFormControl.password.errors?.required) ? 'is-invalid' : '')"
                        formControlName="password" type="password" name="" class="form-control input_pass"
                        placeholder="Password">
                </div>
                <div
                    *ngIf="(LoginFormControl.password.touched && LoginFormControl.password.errors) || (submitted && LoginFormControl.password.errors)">

                    <div class='text-danger' *ngIf="LoginFormControl.password.errors.pattern">
                        Password is invalid
                    </div>
                    <div class='text-danger' *ngIf="LoginFormControl.password.errors.required">
                        Password is required
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-3 login_container">
                    <button type="submit" name="button" class="btn login_btn">Login</button>
                </div>
            </form>
        </div>

        <div class="mt-4">
            <div class="d-flex justify-content-center links">
                Don't have an account? <a href="javascript:void(0)" (click)="goToSignUp()" class="ml-2">Sign
                    Up</a>
            </div>
            <div class="d-flex justify-content-center links">
                <a href="javascript:void(0)" [routerLink]="['/forgotpassword']">Forgot your password?</a>
            </div>
        </div>
    </div>
</div>